import React from 'react'
import { StyledContactWrapper } from './styles/StyledContact'
import ContactForm from './ContactForm'

function Contact() {
    return (
        
            <StyledContactWrapper id="Contact">
                <div className="bottom">
                    <ContactForm />
                    
                </div>
                {/* <footer></footer> */}
            </StyledContactWrapper>
            
      
    )   
}

export default Contact