import styled from 'styled-components'

export const StyledAboutWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    // background: linear-gradient(180deg, #ffc09f 50%, #F9F9F9 50%);
    
    // text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: -1px;
    

    
`

export const StyledAbout = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    padding-top: 10vh;
    // line-spacing: 10px;
    flex-direction: column;
    align-items: center;
    background-color: #F9F9F9;
    // background: linear-gradient(180deg, #ffc09f 50%, #F9F9F9 50%);
    background: #000000;

    .top {
        display: flex;
        flex-direction: column;
        height: 40vh;
        width: 70vw;
        text-align: center;
        justify-content: center;
        @media only screen and (max-width: 500px) {
            font-size: 8pt;
            line-spacing: 3px;
            width: 90%;
            text-align: justify;
        } 

    }

    .intro {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        text-align: justify;
        font-size: 19pt;
        color: #F9F9F9;
        line-spacing: 16px;
        line-height: 3rem;

        @media screen 
        and (max-width: 1023px) 
        and (min-width: 729px)
        {
            font-size: 16pt;
            line-height: 1.9rem;
        }

        @media screen 
        and (max-width: 728px) 
        and (min-width: 501px)
        {
            font-size: 14pt;
            line-height: 1.7rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 12pt;
            line-height: 1.5rem;
        }

        @media screen and (max-height: 700px) {
            font-size: 11pt;
        }

        // @media screen and (max-height: 700px) and (max-width: 700px) {
        //     font-size: 12pt;
        // }

        @media screen and (max-height: 500px) {
            font-size: 6pt;
            line-height: 1.1rem;
        }
        
    }

    a {
        color: #5bc0eb;
    }

    .bottom {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        // align-content: flex-end;
        height: 45vh;
        width: 100vw;
        margin: 0;
        margin-top: 9vh;
        
        width: 100vw;
        @media only screen and (max-width: 500px) {
            width: 100vw;
            margin-top: 2vh;
        }

        .atagwrap {
            flex: 1 1 33.3333%;
            text-align: center;
            margin: 0;
            padding: 0;
            
        }
        a {
            align-items: center;
            text-align: center;
            text-decoration: none;
            margin: 0;
            padding: 0;
            
        }
    }

    .technology-icon {
        flex: 1 1 33%;
        color: #f9f9f9;
        font-size: 5.0rem;
        padding-top: 20px;

        @media screen 
        and (max-width: 728px) 
        and (min-width: 501px)
        {
            font-size: 4rem;
        }
    
        @media screen and (max-width: 500px) {
            font-size: 4rem;
            margin-top: 5vh;
        }

        @media screen and (max-height: 700px) {
            font-size: 3.0rem;
        }

        @media screen and (max-height: 500px) {
            font-size: 2.5rem;
           
        }



    }

    .technology-icon:hover {
        filter: drop-shadow(1px 2px 2px #6b6b6b);


    }

    a {
        text-decoration: none;
    }







`