import styled from 'styled-components'

export const StyledContactWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // background: linear-gradient(180deg, #F9F9F9 90%, #ffc09f 90%);
    background: #000000;
    
    
    letter-spacing: -0.1rem;
    margin-top: -1px;

    // footer {
    //     width: 100vw;
    //     height: 100px;
    //     background-color: #000000;
    // }
   

    

    .top {
        font-size: 200px;
        font-weight: bold;
        
        
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        @media (max-width: 600px) {
            font-size: 10px;
         }
    }

    .bottom {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold;
        margin-top: 8vh;


        @media (max-width: 600px) {
            text-align: center;
            width: 100%;
           
         }
    }

    .contact-top {
        color: #f9f9f9;
        font-size: 36pt;

        

    }

    .contact-bottom {
        color: #f9f9f9;
        font-size: 16pt;

        @media (max-width: 600px) {
            text-align: center;
            font-size: 16px;
         }
    }

    @media (max-width: 600px) {
       font-size: 10px;
    }


`