import React from 'react'
import Slider from './Slider'
import { StyledCarousel } from './styles/StyledCarousel'


const Carousel = () => {

    const images = [
        "images/vyoHome.png",
        "images/stm3.png",
        "images/fallingspheres.png",
        "images/vaner.png"
    ]

    const links = [
        "https://vyochat.amvdxc.com/",
        "https://searchthemet.amvdxc.com/",
        "https://fallingspheres.amvdxc.com/",
        "https://vaner-tokyo.web.app/" 
    ]

    return (
        
            <StyledCarousel id="Projects">
                <Slider slides={images} urls={links} />
            </StyledCarousel>
       
    )
}

export default Carousel