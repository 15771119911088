/** @jsxRuntime classic */
/** @jsx jsx */
import { memo } from 'react'
import { css, jsx } from '@emotion/react'


const Slide = ({ content, url, width }) => {

    return (

        <div 
            
            css={css`
                height: 100%;
                width: ${width}px;
                background-image: url('${content}');
                background-size: 600px;
                border-image: #f9f9f9;
                display: flex;
                align-items: center;
                justify-content: center;
                background-repeat: no-repeat;
                background-position: center;

                
                

                @media only screen
                and (max-width: 1000px) {
                    background-size: 625px;
                    background-position: center;
                }

                @media only screen
                and (max-width: 720px) {
                    background-size: 400px;
                }

                @media only screen
                and (max-width: 500px) {
                    background-size: 300px;
                    
                 
                }
            
            `}
            
        >
            
            <a 
                css={css`
                    height: 250px;
                    width: 250px;
                    display: hidden;
                    color: transparent;

                    @media only screen
                    and (max-width: 480px) {
                        height: 175px;
                        width: 175px;
                    }
                `}
                href={url}>Link</a>
        </div>

  
    )
}

export default memo(Slide)