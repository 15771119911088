/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import SliderContent from './SliderContent'
import { SliderWrapper } from './styles/StyledSlider'
import Slide from './Slide'
import Arrow from './Arrow'
import Dots from './Dots'



/**
 * @function Slider
 */


const getWidth = () => window.innerWidth

const Slider = props => {

    const { slides } = props

    const firstSlide = slides[0]
    const secondSlide = slides[1]
    const lastSlide = slides[slides.length - 1]


    const [transValues, setTransValues] = useState({
        activeIndex: 0,
        translate: getWidth(),
        transition: 0.45,
        _slides: [lastSlide, firstSlide, secondSlide]
    })

    const { translate, transition, activeIndex, _slides } = transValues

    const resizeRef = useRef()
    const transitionRef = useRef()
    const sliderRef = useRef()

    useEffect(() => {
        resizeRef.current = handleResize
        transitionRef.current = smoothTransition
    })

    useEffect(() => {
        const slider = sliderRef.current

        const smooth = e => {
            if(e.target.className.includes('SliderContent')) {
                transitionRef.current()
            }
         }

        const resize = () => {
            resizeRef.current()
        }

    
        const transitionEnd = slider.addEventListener('transitionend', smooth)
        const onResize = window.addEventListener('resize', resize)

        return () => {
            slider.removeEventListener('transitionend', transitionEnd)
            window.removeEventListener('resize', onResize)
        }
    }, [])

    useEffect(() => {
        if(transition === 0) setTransValues({ ...transValues, transition: 0.45 })
    }, [transition])

    const handleResize = () => {
        setTransValues({ ...transValues, translate: getWidth(), transition: 0 })
    }

    const smoothTransition = () => {
        let _slides = []

        if(activeIndex === slides.length - 1) {
            _slides = [slides[slides.length - 2], lastSlide, firstSlide]
        }
        if(activeIndex === 0) {
            _slides = [lastSlide, firstSlide, secondSlide]
        }
        else {
            _slides = slides.slice(activeIndex - 1, activeIndex + 2)
        }

        setTransValues({
            ...transValues,
            _slides,
            transition: 0,
            translate: getWidth()
        })
        
    }

    const nextSlide = () => {
        setTransValues({
            ...transValues,
            translate: translate + getWidth(),
            activeIndex: activeIndex === slides.length - 1 ? 0 : activeIndex + 1
        })
        
       
    }
    
    const prevSlide = () => {
        setTransValues({
            ...transValues,
            translate: 0,
            activeIndex: activeIndex === 0 ? slides.length - 1 : activeIndex - 1
        })
    }
    // const autoPlayRef = useRef()

    // useEffect(() => {
    //     autoPlayRef.current = nextSlide
    // })

    // useEffect(() => {
    //     const play = () => {
    //         autoPlayRef.current()
    //     }

    //     const interval = setInterval(play, props.autoPlay * 1000)
    //     return () => clearInterval(interval)
    // }, [])

    // const nextSlide = () => {
    //     if(activeIndex === props.slides.length - 1) {
    //         return setTransValues({
    //             ...transValues,
    //             translate: 0,
    //             activeIndex: 0
    //         })
    //     }

    //     setTransValues({
    //         ...transValues,
    //         activeIndex: activeIndex + 1,
    //         translate: (activeIndex + 1) * getWidth()
    //     })
    // }

    // const prevSlide = () => {
    //     if(activeIndex === 0) {
    //         return setTransValues({
    //             ...transValues,
    //             translate: (props.slides.length - 1) * getWidth(),
    //             activeIndex: props.slides.length - 1
    //         })
    //     }

    //     setTransValues({
    //         ...transValues,
    //         activeIndex: activeIndex - 1,
    //         translate: (activeIndex - 1) * getWidth()
    //     })
    // }

    

    return (
        <SliderWrapper ref={sliderRef}>
            <SliderContent
                translate={translate}
                transition={transition}
                width={getWidth() * _slides.length}
            >
                {_slides.map((_slide, i) => (
                    <Slide width={getWidth()} key={_slide + i} content={_slide} url={props.urls[activeIndex]} />
                ))}
            </SliderContent>
            
            <Arrow direction="left" handleClick={prevSlide} />
            <Arrow direction="right" handleClick={nextSlide} />

            <Dots slides={props.slides} activeIndex={activeIndex} />
            
        </SliderWrapper>
    )
}

// Slider.defaultProps = {
//     slides: [],
//     autoPlay: null
// }


export default Slider