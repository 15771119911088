import styled from 'styled-components'

export const StyledTopbarWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    
    @media all and (max-width: 500px) {
        width: 100%;
    }
    
`