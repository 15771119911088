import styled from 'styled-components'

export const StyledContactForm = styled.div`


    width: 100vw;
    height: 66vh;
    text-align: center;
    
    .contact-top {
        color: #f9f9f9;
        font-size: 42pt;
        margin: 0 auto;
        padding: 0 auto;
        
        font-family: 'Proza Libre', sans-serif;
       
        @media screen and (max-width: 500px) {
            font-size: 28pt;
            
        }
    }

    .contact-header {
        font-size: 21pt;
        color: #f9f9f9;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 40px;

        @media screen and (max-width: 500px) {
            font-size: 16pt;
        }
    }

    form {
        margin: 0;
        height: 50vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        



        label {
            padding: 10px;
            font-weight: bold;
            font-family: 'Proza Libre', sans-serif;
            color: #f9f9f9;
           
        }

        input {
            width: 500px;
            height:40px;
            // border: 0.5px solid #5bc0eb;
            border: none;
            text-indent: 5px;
            color: #000000;
            font-weight: bold;
            border-radius: 3px;
            margin-bottom: 20px;
            font-family: 'Poppins', sans-serif; 
            

            &:hover {
                box-shadow: -0.1px -0.1px 0.6em #f9f9f9;
                
            }

            &:focus {
                color: #000000;
                font-weight: bold;
                outline: none;
                
            }
        
        

            ::placeholder {
                font-size: 10pt;
                font-weight: bold;
                color: #000000;
                font-family: 'Poppins', sans-serif; 

                
                
            }
            

        }

        textarea {
            width: 500px;
            // border: 0.5px solid #5bc0eb;
            border: none;
            font-weight: bold;
            font-family: 'Poppins', sans-serif;  
            color: #000000;
            border-radius: 3px;
            text-indent: 5px;
            padding-top: 8px;
            min-height: 30px;
            
            &:hover {
                box-shadow: -0.1px -0.1px 0.6em #f9f9f9;
                
            }

            ::placeholder {
                font-family: Arial;
                font-size: 10pt;
                font-weight: bold;
                
                color: #000000;
                font-family: 'Poppins', sans-serif; 
            }

            &:focus {
                color: #000000;
                font-family: 'Poppins', sans-serif; 
                font-weight: bold;
                outline: none;
                
               
            }
        
        }

        button {
            margin-top: 3vh;
            width: 200px;
            height: 40px;
            border: 2px solid #f9f9f9;
            border-radius: 3px;
            background-color: #f9f9f9;
            color: #000000;
            font-weight: bold;
            font-size: 12pt;
            font-family: 'Poppins', sans-serif; 
           

            &:hover {
                cursor: pointer;
                box-shadow: -0.1px -0.1px 0.6em #f9f9f9;
                // box-shadow: 2px 1px 0.2em #ffeeee;
            }

            &:focus {
                outline: none;
            }
        }

        @media (max-width: 500px) {
            input {
                width: 50vw;
            }

            textarea {
                width: 50vw;
            }
        })


    
    }
`