import React from 'react'
// import { useSpring } from 'react-spring'
import { StyledAboutWrapper, StyledAbout } from './styles/StyledAbout'
import { GrNode, GrReactjs, GrGithub } from 'react-icons/gr'
import { SiJavascript, SiCss3, SiHtml5, SiFirebase, SiSocketDotIo, SiWebrtc } from 'react-icons/si'
import HoverMove from './effects/HoverMove'

function About() {
    return(
            <StyledAboutWrapper id="About">
                <StyledAbout>
                    <div className="top">
                        <h1 className="intro">
                            But feel free to call me Max.
                            I am a multidisciplinary developer from California, living in Tokyo, Japan.
                            I have always found joy in designing and building things.
                            I gravitated towards the world of programming
                            with the intention of creating interesting and inspiring experiences.
                            I am an avid reader and student.
                            I spend a lot of time learning about and making use of 
                            tools like WebRTC, ThreeJS, socketIO, and countless other open source libraries and frameworks.
                            
                        </h1>

                    </div>
                    <div className="bottom">
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://nodejs.org/en/about/"><HoverMove><GrNode className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://reactjs.org"><HoverMove><GrReactjs className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://github.com/about"><HoverMove><GrGithub className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://developer.mozilla.org/en-US/docs/Web/CSS"><HoverMove><SiCss3 className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"><HoverMove><SiJavascript className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"><HoverMove><SiHtml5 className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com"><HoverMove><SiFirebase className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://socket.io"><HoverMove><SiSocketDotIo className="technology-icon" /></HoverMove></a></div>
                        <div className="atagwrap"><a target="_blank" rel="noopener noreferrer" href="https://webrtc.org"><HoverMove><SiWebrtc className="technology-icon" /></HoverMove></a></div>

                    </div>
                </StyledAbout>
            </StyledAboutWrapper>
       
    )
}

export default About