import React from 'react'
import { StyledContactForm } from './styles/StyledContactForm'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from 'emailjs-com'



const ContactForm = () => {

    const { register, errors, handleSubmit, reset } = useForm()


    const toastifySuccess = () => {
        toast("Thanks! I will get back to you soon.", {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        })
    }

    const onSubmit = async (data) => {
        try {
            const templateParams = {
                name: data.name,
                email: data.email,
                message: data.message
            }
    
            await emailjs.send(
               'service_pr7raow',
               'template_fyawxri',
               templateParams,
               'user_Tv9z6jurKjTEA5VSM4Uyf'
            )
            reset()
            toastifySuccess()
        } catch (error) {
            console.log(error)
        }
    }

    return (
       <StyledContactForm>
           <h1 className="contact-top">Get in touch!</h1>
           <h3 className="contact-header">It'd be great to hear from you.</h3>
           <form name="contact" onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* <input type="hidden" name="form-name" value="contact" /> */}
                {/* <label>Name:</label> */}
                    <input 
                    type="text" 
                    placeholder="Name..." 
                    name="name" 
                    ref={register({
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                            value: 30,
                            message: 'No more than 30 characters, please.'
                        }
                    })}
                    />
                    {errors.name && <span className="errorMessage">{errors.name.message}</span>}
                {/* <label>Email:</label> */}
                    <input
                        type="text" 
                        placeholder="Email..." 
                        name="email" 
                        ref={register({
                            required: true,
                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        })}
                    />
                    {errors.email && <span className="errorMessage">Please enter a valid email address.</span>}
                {/* <label>Message:</label> */}
                    <textarea
                        rows="10" 
                        placeholder="Message..." 
                        name="message"
                        ref={register({
                            required: true
                        })}
                    >

                    </textarea>
                    {errors.message && <span className="errorMessage">Please enter a message to send.</span>}
                <button type="submit">Send</button>
           </form>
           <ToastContainer />
       </StyledContactForm>
    )
}

export default ContactForm
