import styled from 'styled-components'

export const StyledMenu = styled.nav`
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    // background: #F9F9F9;
    background: #000000;
    height: 100vh;
    // width: 10px;
    text-align: left;
    padding-top: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;

    transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
    // box-shadow: ${({ open }) => open ? ' 3px 1px #6b6b6b' : '0px 0px 0px #000000'};
    // padding-bottom: ${({ open }) => open ? '10px' : '0'};
   
  
    @media (max-width: 700px) {
        justify-content: center;
        align-items: center;
        width: 100%;
      }

    a {
        z-index: 10;
        font-size: 1rem;
        // text-transform: uppercase;
        font-family: 'Poppins', Arial;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        // color: #000000;
        color: #f9f9f9;
        text-decoration: none;
        transition: color 0.3s linear;
        // transform: ${({ open }) => open ? 'rotate(0deg)' : 'rotate(-90deg)'};
        transform: rotate(-45deg);
        animation: 2s ease-in;

        &:hover {
            text-decoration: underline;
        }

        @media screen and (max-width: 700px) {
            font-size: 1.3rem;
            transform: rotate(0deg);
            line-height: 0.6rem;
        }
    }


`
