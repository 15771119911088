import styled from 'styled-components'

export const StyledBurger = styled.button`
  position: absolute;
  top: 1.6%;
  left: 1.1%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-bottom: 0;
  z-index: 100;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;


  
  
  @media (max-width: 700px) {
    left: 1.0rem;
    top: 2%;
  }

&:focus {
  outline: none;
}

div {
  z-index: 10;
  width: 2rem;
  height: 0.7px;
  border-radius: 15px;
  background: ${({ open }) => open ? '#f9f9f9' : '#f9f9f9'};
  box-shadow: ${({ open }) => open ? '0px 0px 0px #000000' : '1px 1px 1px #000000'};
  
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1.4px;
  
  // @media (max-width: 700px) {
  //   width: 75%;
  //   height: 12%;
  //   transform-origin: -14px;
  // }

  :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      z-index: 10;

  }

  :nth-child(2) {
        z-index: 10;
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
   
  }

  :nth-child(3) {
        z-index: 10;
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
   
  }

}

`