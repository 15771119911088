import React, { useState, useRef } from 'react'
import { useOnClickOutside } from '../hooks/hooks'
import { StyledTopbarWrapper } from './styles/StyledSidebar'
import Burger from './Burger'
import Menu from './Menu'


function Topbar() {
    const [open, setOpen] = useState(false)
    const node = useRef()
    useOnClickOutside(node, () => setOpen(false))
    

    return (
        <StyledTopbarWrapper ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} innerRef={node}/>
        </StyledTopbarWrapper>
    )
}

export default Topbar