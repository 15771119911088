import React from 'react'
import { StyledMenu } from './styles/StyledMenu'
import { bool } from 'prop-types'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import { BrowserRouter } from 'react-router-dom'


const Menu = ({ open, setOpen }) => {
    return (
        <BrowserRouter>
            <StyledMenu className="nav-menu" open={open} >
                <NavLink smooth to="#Home" onClick={() => setOpen(!open)}  >
                    Home
                </NavLink>

                <NavLink smooth to="#About" onClick={() => setOpen(!open)} >
                    About
                </NavLink>
               
                <NavLink smooth to="#Projects" onClick={() => setOpen(!open)} >
                    Projects
                </NavLink>
               
                <NavLink smooth to="#Contact" onClick={() => setOpen(!open)} >
                    Contact
                </NavLink>

                <a href="https://github.com/ashleymvanduzer" target="_blank" rel="noopener noreferrer">Github</a>
                <a href="https://www.twitter.com/amvdxc/" target="_blank" rel="noopener noreferrer">Twitter</a>
            </StyledMenu>


        </BrowserRouter>

    )
}

Menu.propTypes = {
    open: bool.isRequired
}

export default Menu