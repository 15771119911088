/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'

const Arrow = ({ direction, handleClick }) => (
    <div
        onClick={handleClick}
        css={css`
            display: flex;
            position: absolute;
            top: 50%;
            font-size: 20pt;
            ${direction === 'right' ? `right: 20px` : `left: 20px`};
            height: 50px;
            width: 50px;
            justify-content: center;
            background: black;
            // opacity: 0.4;
            border-radius: 50%;
            cursor: pointer;
            align-items: center;
            transition: transform ease-in 0.1s;
            &:hover {
                // transform: scale(1.1);
                box-shadow: -0.1px -0.1px 0.2em #f9f9f9;
                opacity: 1;
            }
            img {
                transform: translateX(${direction === 'left' ? '-2' : '2'}px);
                &:focus {
                    outline: 0;
                }
            }

            @media only screen
            and (max-width: 720px) {
               height: 30px;
               width: 30px;
            }

            @media only screen
            and (max-width: 480px) {
                top: 50%;
            }


        `}    
    >
        {direction === 'right' ? <FiArrowRight color={'#f9f9f9'} /> : <FiArrowLeft color={'#f9f9f9'} />}
    </div>
)

export default Arrow