/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

const Dot = ({ active }) => (
    <span
        css={css`
            padding: 4px;
            // margin-right: 5px;
            margin: 1px;
            cursor: pointer;
            border-radius: 50%;
            background: ${active ? 'grey' : '#f9f9f9'};
            opacity: 0.7;
        `}
        >

    </span>
)

const Dots = ({ slides, activeIndex }) => (
    <div 
        css={css`
            position: absolute;
            bottom: 25px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 40px;

            @media screen and (max-width: 500px) {
                padding-bottom: 50px;
            }
        `}
    >
        {slides.map((slide, i) => (
            <Dot key={slide} active={activeIndex === i} />
        ))}
    </div>
)

export default Dots