

import styled from 'styled-components'


export const StyledHomeWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: 'Proza Libre', sans-serif;
    background: #000000;
    background-image: radial-gradient(#f9f9f9 2%, transparent 0), radial-gradient(#f9f9f9 2%, transparent 0);
    background-position: center;
    background-size: 150px 150px;
    background-position: 10 10, 25px 25px;
    margin-top: -1px;
    // border: none;
    padding-left: 1px;


    
    z-index: 1;
    // animation: 2s slideInFromTop;
    outline: 0px;
    letter-spacing: -0.2rem;




    .name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        // left: 50%;
        // top: 100%;
        width: 10%;
        color: #F9F9F9;
        // letter-spacing: -5px;

        

        .intro-container {

            justify-content: space-between;
            width:50vw;
            height: 20vh;
        }

        h1 {
            width: 100%;
            height: 20%;
        }

        
        span {
            height: 110px;
            transform: rotate(90deg);
            width: 10%;
            color: #f9f9f9;
            font-weight: bold;
            margin-top: -500px;

            
            // text-shadow: 2px 2px 2px white;
            // animation: 2s slideInFromTop;
            
        }
    
        .box {
            background-color: white;
            height: 10px;
        }


         
    }





    @keyframes slideInFromTop {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
    }






`

export const StyledHomeScroll = styled.div`
    position: absolute;
    width: 100vw;
    bottom: 7%;
    
    
    

    .scroll {
        font-size: 20pt;
    
        color: #f9f9f9;
        position: relative;
        right: 1.6px;
        margin-bottom: 5px;
        // right: 50%;
        // text-align: center;
        animation: MoveUpDown 1s linear infinite;
       
    }

    @keyframes MoveUpDown {
        0%, 100% {
            bottom: 0;
        }
        50% {
            bottom: 10px;
        }
    }

`