import React from 'react'
import { StyledBurger } from './styles/StyledBurger'

const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger aria-label="Nav Toggle" open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>      
    )
}

export default Burger