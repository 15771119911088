import React, { useRef } from 'react'
import { StyledHomeWrapper, StyledHomeScroll } from './styles/StyledHome'
import { CgScrollV } from 'react-icons/cg'
import { useTrail, useSpring, useChain, animated, config} from 'react-spring'
import { useMediaQuery } from '@material-ui/core'



const letters = ["I", "'", "m", '\u00A0', "A", "s", "h", "l", "e", "y", "."]

function Home({open, setOpen}) {
    const matches = useMediaQuery('(max-width: 500px)')
    const springRef = useRef()
    const spring = useSpring({
        ref: springRef,
        from: { fontSize: matches ? "62pt" : "82pt" },
        to: { fontSize: matches ? "42pt" : "62pt" },
        config: config.gentle
    })

    
    const trailRef = useRef()
    const trails = useTrail(letters.length, {
        ref: trailRef,
       
        
        from: {
            
            marginTop: -1000,
            // opacity: 0,
            transform: `rotate(20deg)`
            
          

        },
        to: {
       
            marginTop: 0,
            // opacity: 1,
            transform: `rotate(0deg)`
           
          
        },
        config: config.default,
    })

    useChain([trailRef, springRef])

        return (
                <StyledHomeWrapper id="Home">
                        
                            <animated.div className="name" >
                                {trails.map((animation, index) => (
                                    <animated.div 
                                    key={index}
                                    className="intro-container"
                                    style=
                                    {{
                                        ...animation,
                                        ...spring,
                                    }}>
                                        <animated.span>
                                            {letters[index]}
                                        </animated.span>
                                    </animated.div>
                                    
                                ))}
                            </animated.div>
                <StyledHomeScroll>
                        <CgScrollV className="scroll" />
                </StyledHomeScroll>
           </StyledHomeWrapper>
            )
}

export default Home