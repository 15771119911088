import React, {useState} from 'react'

import Topbar from './components/Sidebar'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Carousel from './components/Carousel'

import './App.css'

function App () {
    const [open, setOpen] = useState(true)
    

    return (
        <div className="app-wrapper">
          <Topbar />
          <Home open={open} setOpen={setOpen} />
          <About />
          <Carousel />
          <Contact />
        </div>
    )
  }

export default App;
