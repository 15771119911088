import styled from 'styled-components'

export const SliderWrapper = styled.div`
   
    height: 100vh;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background: linear-gradient(135deg, #F9F9F9 50%, #ffc09f 50%);
    background: #000000;
    position: relative;
    background-image: radial-gradient(#f9f9f9 2%, transparent 0), radial-gradient(#f9f9f9 2%, transparent 0);
    background-position: center;
    background-size: 150px 150px;
    background-position: 10 10, 25px 25px;
    
`